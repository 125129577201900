import React from "react"

import Layout from "../components/layouts/layout"
import HeadMeta from "../components/layouts/headmeta"
import ProgressBar from "../components/layouts/progressbar"
import { changeProgressBarOnScroll } from "../components/utils/index"

import API from "../services/api"

export default class BlogPostPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      post: "",
      image: "",
      categories: [],
    }
  }

  componentDidMount() {
    var self = this
    var pathname = window.location.pathname,
      slug = pathname.substr(pathname.lastIndexOf("/") + 1)
    API.getPost(slug).then(function(resp) {
      self.setState({
        post: resp.data[0],
        image: resp.data[0]["better_featured_image"]
          ? resp.data[0]["better_featured_image"]["media_details"]["file"]
          : "",
      })
      resp.data[0]["categories"].map(id => {
        API.getPostCategories(id).then(function(data) {
          self.setState(prevState => ({
            categories: [...prevState.categories, data["data"]["name"]],
          }))
        })
      })
    })
  }

  render() {
    const { image, categories, post } = this.state

    return (
      <Layout>
        <div id="resource">
          <HeadMeta
            metadata={{
              title: post && post["title"]["rendered"],
              description:
                post &&
                post["excerpt"]["rendered"]
                  .substring(0, 150)
                  .replace(/(<([^>]+)>)/gi, ""),
              url: `https://advanxhealth.com/blog/post/${post && post["slug"]}`,
            }}
          />
          <ProgressBar />
          <section
            className={`hero hero-resource ${
              image == "" ? " bg-primary-blue-light" : "bg-gray"
            }`}
            style={{
              backgroundImage: `url(https://blog.advanxhealth.com/wp-content/uploads/${image})`,
            }}
          >
            <div className="container pt-5">
              <div className="row text-center">
                <div className="col-lg-12">
                  <h5>
                    {categories.map((category, index) => {
                      return (
                        <span
                          class="badge badge-primary-pink ml-2"
                          dangerouslySetInnerHTML={{
                            __html: category,
                          }}
                        ></span>
                      )
                    })}
                  </h5>
                </div>
                <div className="col-lg-12">
                  <h2 className="text-white">
                    {post && post["title"]["rendered"]}
                  </h2>
                </div>
              </div>
            </div>
          </section>
          <section className="bg-white">
            <div className="container blog-post-content">
              <div className="row pb-5 ">
                <div
                  className="col-12"
                  dangerouslySetInnerHTML={{
                    __html: post && post["content"]["rendered"],
                  }}
                ></div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}
// export const BlogPostQuery = graphql`
//   query BlogPostPage($slug: String!) {
//     blogPost(fields: { slug: { eq: $slug } }) {
//       fields {
//         slug
//       }
//     }
//   }
// `
